import React from "react";
import { Link } from "react-router-dom";
import { getCurrentUser as user } from "../services/authServices";

// import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";

import MobileLogo from "../components/navbar/m.logo";
import MobileSearch from "../components/navbar/m.search";
import MobileMenu from "../components/navbar/m.menu";
import MobileNavAuth from "../components/navbar/m.nav.auth";
import DesktopMenu from "../components/navbar/d.menu";
import DesktopSearch from "../components/navbar/d.search";
import DesktopNavAuth from "../components/navbar/d.nav.auth";
import VerifyToken from "../components/tokenCheck/VerifyToken";

function Navbar({ search }) {
  const currentUser = user();
  return (
    <>
      <header>
        {currentUser && <VerifyToken />}
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
          <div className="container">
            <Link className="navbar-brand me-5" to="/">
              {/* <ZuluLogo className="logo" alt="zulucast logo" /> */}African
              Movies
            </Link>

            {/* <!-- Mobile view Menu --> */}

            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
            >
              <i className="fas fa-bars fa-lg"></i>
            </button>

            <div
              className="offcanvas offcanvas-end d-block d-lg-none"
              tabIndex="-1"
              id="offcanvasBottom"
              aria-labelledby="offcanvasMenu"
            >
              <MobileLogo />
              <div className="offcanvas-body small text-center">
                <ul className="list-group">
                  <MobileSearch />
                  <MobileMenu />
                  <MobileNavAuth />
                </ul>
              </div>
            </div>

            {/* <!-- Desktop Menu items --> */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <DesktopMenu />
              <ul className="navbar-nav ms-auto">
                <DesktopSearch search={search} />
                <DesktopNavAuth />
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
