import React from "react";
import { Link } from "react-router-dom";

// import hdLogo from "../assets/images/hd.png";
import FacebookShare from "../components/movieDetails/FacebookShare";
import Favorite from "../components/movieDetails/Favorite";
import TwitterShare from "../components/movieDetails/TwitterShare";
import Watchlist from "../components/movieDetails/Watchlist";
import WatchMovie from "../components/movieLink/WatchMovie";

function MovieDetailsBanner({ movie }) {
  return (
    <section className="banner movie">
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {!movie?.movieBannerPictureURL ? (
            <div
              className="carousel-item active"
              data-bs-interval="7000"
              style={{
                backgroundImage: `url(${movie?.movieBannerPictureURL})`,
              }}
            >
              <div className="carousel-caption bg-dark-gradient-0 d-none d-md-block">
                <div className="container position-relative">
                  <div className="movie-details text-start text-white">
                    <h1 className="banner-movie-title">{movie?.title}</h1>
                    <div>
                      <span>
                        <Link
                          to="/"
                          className="text-white text-decoration-none font-bold"
                        >
                          {movie?.title}{" "}
                        </Link>
                      </span>
                      |
                      <span>
                        <Link
                          to="/"
                          className="font-bold text-decoration-none text-white"
                        >
                          {" "}
                          {movie?.releaseYear}{" "}
                        </Link>
                      </span>
                      <span>
                        <Link
                          to="/"
                          className="font-light text-decoration-none text-white-50"
                        >
                          | {movie?.duration} min{" "}
                        </Link>
                      </span>
                      <span>
                        {/* <Link
                          to="/"
                          className="
                        font-light
                        text-decoration-none text-muted
                        hd-logo
                      "
                        >
                          <img src={hdLogo} className="" alt="" />
                        </Link> */}
                      </span>
                      {movie?.rating ? (
                        <span className="rating">{movie.rating}+</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-8">
                      <WatchMovie
                        movie={movie}
                        cssstyle={
                          "btn btn-primary px-5 float-start text-uppercase me-3"
                        }
                      />
                      <Link
                        to={`/trailer/${movie?._id}`}
                        className="btn btn-secondary px-5 float-start text-uppercase"
                      >
                        Watch Trailer
                      </Link>
                    </div>
                    <div className="col-md-4 text-end">
                      <Favorite movie={movie} />
                      <Watchlist movie={movie} />
                      <TwitterShare
                        title={`Watch ${movie?.title} on African Movies.`}
                        url={`${process.env.REACT_APP_NOTIFICATION_URL}/moviedetails/${movie?._id}`}
                      />
                      <FacebookShare
                        title={`Watch ${movie?.title} on African Movies.`}
                        url={`${process.env.REACT_APP_NOTIFICATION_URL}/moviedetails/${movie?._id}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="carousel-item active" data-bs-interval="7000">
              <video autoPlay muted loop playsInline>
                <source src={movie?.movieTrailerURL} type="video/mp4" />
                Your browser does not support this File extension.
              </video>
              <div className="carousel-caption bg-dark-gradient-0 d-none d-md-block">
                <div className="container position-relative">
                  <div className="movie-details text-start text-white">
                    <h1 className="banner-movie-title">{movie?.title}</h1>
                    <div>
                      <span>
                        <Link
                          to="/"
                          className="text-white text-decoration-none font-bold"
                        >
                          {movie?.title}{" "}
                        </Link>
                      </span>
                      |
                      <span>
                        <Link
                          to="/"
                          className="font-bold text-decoration-none text-white"
                        >
                          {" "}
                          {movie?.releaseYear}{" "}
                        </Link>
                      </span>
                      <span>
                        <Link
                          to="/"
                          className="font-light text-decoration-none text-white-50"
                        >
                          | {movie?.duration} min{" "}
                        </Link>
                      </span>
                      <span>
                        {/* <Link
                          to="/"
                          className="
                        font-light
                        text-decoration-none text-muted
                        hd-logo
                      "
                        >
                          <img src={hdLogo} className="" alt="" />
                        </Link> */}
                      </span>
                      {movie?.rating ? (
                        <span className="rating">{movie.rating}+</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-8">
                      <WatchMovie
                        movie={movie}
                        cssstyle={
                          "btn btn-primary px-5 float-start text-uppercase me-3"
                        }
                      />
                      <Link
                        to={`/trailer/${movie?._id}`}
                        className="btn btn-secondary px-5 float-start text-uppercase"
                      >
                        Watch Trailer
                      </Link>
                    </div>
                    <div className="col-md-4 text-end">
                      <Favorite movie={movie} />
                      <Watchlist movie={movie} />
                      <TwitterShare
                        title={`Watch ${movie?.title} on African Movies.`}
                        url={`${process.env.REACT_APP_NOTIFICATION_URL}/moviedetails/${movie?._id}`}
                      />
                      <FacebookShare
                        title={`Watch ${movie?.title} on African Movies.`}
                        url={`${process.env.REACT_APP_NOTIFICATION_URL}/moviedetails/${movie?._id}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default MovieDetailsBanner;
